import React from 'react'
import { useTranslation } from 'react-i18next';
import { usePageContext } from '../../pageContext';
import EducationItem from '../elements/EducationItem'
import WorkExperienceItem from '../elements/WorkExperienceItem'

function EducationContainer({ lang }) {

    const languageData = useTranslation()[1].store.data;

    const echo = (expression) => {
        return languageData[lang]['educationContainer'][expression];
    }

    const echo_e = (index, expression) => {
        return languageData[lang]['educationContainer']['educations'][`${index}`][expression];
    }

    const echo_w = (index, expression) => {
        return languageData[lang]['educationContainer']['works'][`${index}`][expression];
    }


    return (
        <div className="container-fluid">

            {/* <!-- row --> */}
            <div className="row">

                {/* <!-- col --> */}
                <div className="col-lg-6">

                    {/* <!-- section title --> */}
                    <div className="art-section-title">
                        {/* <!-- title frame --> */}
                        <div className="art-title-frame">
                            {/* <!-- title --> */}
                            <h4>{echo("education")}</h4>
                        </div>
                        {/* <!-- title frame end --> */}
                    </div>
                    {/* <!-- section title end --> */}

                    {/* <!-- timeline --> */}
                    <div className="art-timeline art-gallery" id="history">
                        <EducationItem period={echo_e(1, "period")} school={echo_e(1, "school")} title={echo_e(1, "title")} description={echo_e(1, "description")} />
                        <EducationItem period={echo_e(2, "period")} school={echo_e(2, "school")} title={echo_e(2, "title")} description={echo_e(2, "description")} />
                        <EducationItem period={echo_e(3, "period")} school={echo_e(3, "school")} title={echo_e(3, "title")} description={echo_e(3, "description")} />
                    </div>

                </div>
                <div className="col-lg-6">

                    {/* <!-- section title --> */}
                    <div className="art-section-title">
                        {/* <!-- title frame --> */}
                        <div className="art-title-frame">
                            {/* <!-- title --> */}
                            <h4>{echo("experience")}</h4>
                        </div>
                        {/* <!-- title frame end --> */}
                    </div>
                    {/* <!-- section title end --> */}

                    {/* <!-- timeline --> */}
                    <div className="art-timeline">
                        <WorkExperienceItem entreprise={echo_w(1, "entreprise")} title={echo_w(1, "title")} period={echo_w(1, "period")} description={echo_w(1, "description")} />
                        <WorkExperienceItem entreprise={echo_w(2, "entreprise")} title={echo_w(2, "title")} period={echo_w(2, "period")} description={echo_w(2, "description")} />
                        <WorkExperienceItem entreprise={echo_w(3, "entreprise")} title={echo_w(3, "title")} period={echo_w(3, "period")} description={echo_w(3, "description")} />
                    </div>
                    {/* <!-- timeline end --> */}

                </div>
                {/* <!-- col end --> */}

            </div>
            {/* <!-- row end --> */}

        </div>

    )
}

export default EducationContainer
