import React from 'react'

function EducationItem({ school, title, description, period }) {
    return (
        <div className="art-timeline-item">
            <div className="art-timeline-mark-light"></div>
            <div className="art-timeline-mark"></div>

            <div className="art-a art-timeline-content">
                <div className="art-card-header">
                    <div className="art-left-side">
                        <h5>{school}</h5>
                        <div className="art-el-suptitle mb-15">{title}</div>
                    </div>
                    <div className="art-right-side">
                        <span className="art-date">{period}</span>
                    </div>
                </div>

                <p>{description}</p>
                {/* <a data-fancybox="diplome" href="files/certificate.jpg" className="art-link art-color-link art-w-chevron">Diplome</a> */}
            </div>
        </div>
    )
}

export default EducationItem
