import { graphql } from 'gatsby';
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import ContactInfoContainer from '../components/containers/ContactInfoContainer';
import EducationContainer from '../components/containers/EducationContainer';
import WorksContainer from '../components/containers/WorksContainer';
import Layout from '../components/Layout'
import Seo from '../components/SEO'
import { usePageContext } from '../pageContext';

function ParcoursPage({ location, data }) {

  const languageData = useTranslation()[1].store.data;

  const { lang } = usePageContext();

  const echo = (expression) => {
    return languageData[lang]['historyPage'][expression];
  }

    return (
        <Fragment>
            <Seo title={`Hanniel TSASSE - ${lang == 'fr' ? 'Mon parcours' : 'My history'}`} lang='fr' description="Parcours académique et professionnel de Hanniel TSASSE" />

            <Layout data={data} lang={lang}>
                <div style={{ marginTop: '25px' }}></div>
                <EducationContainer lang={lang} />
            </Layout>
        </Fragment>
    )
}

export default ParcoursPage;

export const query = graphql`
  query ParcoursPageQuery {
    site {
      siteMetadata {
        siteURL
      }
    }
  }`